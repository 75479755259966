<template>
  <div style="display: flex;flex-direction: column;align-items: center;">
    <img src="@/assets/launch_icon.png" style="width:46%;margin-top: 10%;"/>
    <div style="margin-top: 20px;text-align: left;width: 70%;font-weight: bold;">校外用户注册</div>

    <div style="margin-top: 0px;width: 70%;"> 
      <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 26px;border-bottom: 1px solid #cbcbcb;padding-bottom: 10px;"> 
        <div style="width:30%;color: #5e5e5e;">手机号</div>
        <div style="width:60%"> 
          <input placeholder="请输入手机号" style="border: 0px;" type="number" v-model="form.phone"/>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 26px;border-bottom: 1px solid #cbcbcb;padding-bottom: 10px;"> 
        <div style="width:30%;color: #5e5e5e;">密码</div>
        <div style="width:60%"> 
          <input placeholder="请输入密码" style="border: 0px;" type="password" v-model="form.password"/>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 26px;border-bottom: 1px solid #cbcbcb;padding-bottom: 10px;"> 
        <div style="width:30%;color: #5e5e5e;">确认密码</div>
        <div style="width:60%"> 
          <input placeholder="请再次输入密码" style="border: 0px;" type="password" v-model="form.confirmPassword"/>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 26px;border-bottom: 1px solid #cbcbcb;padding-bottom: 10px;"> 
        <div style="width:30%;color: #5e5e5e;">验证码</div>
        <div style="width:40%"> 
          <input placeholder="请输入验证码" style="border: 0px;" type="number" v-model="form.smsCode"/>
        </div>
        <van-button style="width:20%" size="small" @click="sendCode" :disabled="smsCodeDisabled">发送</van-button>
      </div>
      <div style="margin-top: 20px;color:#aaaaaa;font-size: 12px;display: flex;justify-content: flex-start;align-items: center;"> 
        <!-- <van-checkbox v-model="checked" shape="square" icon-size="14px"><a style="color: #681842;" href="https://cloud.fmyundianji.com/web/userprotocol.html">阅读并同意用户协议与隐私政策</a></van-checkbox> -->
        <!-- <input type="checkbox" name="阅读并同意用户协议与隐私政策" @change="changeCheckbox"/>阅读并同意用户协议与隐私政策 -->
      </div>
      <div style="margin-top: 30px;"> 
        <van-button style="width: 100%;
          color: #ffffff;
          background: #681842;
          border: 0px;
          border-radius: 2px;
          height: 30px;" :loading="loading" type="primary" @click="submit">注册</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import TokenApi from '@/account_api/src/token/api/TokenApi.js'
import { Dialog,Toast } from 'vant';
const tokenApi = new TokenApi()
export default {
  name: 'Container',
  data() {
    return {
      loading: false,
      smsCodeDisabled: false,
      form: {
        phone: undefined,
        password: undefined,
        confirmPassword: undefined,
        smsCode: undefined
      },
      checked: false,
    }
  },

  async mounted(){
    document.title = '校外用户注册';
  },

  
  methods: {
    async sendCode() {
      if(!this.form.phone || this.form.phone.length != 11) {
        Toast('请填写手机号')
        return
      }
      try{
        Toast.loading({
          duration: 0,
          message: '验证码发送中',
          forbidClick: true,
        });
        let resp = await tokenApi.sendPhoneMsg({
          phone: this.form.phone
        })
        console.log(resp)
        Toast.clear();
        Toast.success('验证码发送成功');
        this.smsCodeDisabled = true
      }catch(e){
        console.log(e)
        Toast.fail(e.message);
      }
    },
    async submit() {
      // Dialog({ message: '提示' });
      console.log(this.form)
      console.log(this.checked)
      if(!this.form.password || !this.form.confirmPassword || !this.form.phone || !this.form.smsCode) {
        Toast('请填写全部信息')
        return
      }
      try{
        let resp = await Dialog.confirm({
          title: '标题',
          className: 'left-text',
          message: "感谢您使用泛美教育App!泛美教育App非常重视您的个人信息和隐私保护。" +
            "为了更好地保障您的个人权益，在您使用我们的产品前，" +
            "请务必审慎阅读《隐私政策》和《用户协议》内的所有条款，" +
            "尤其是:\n" +
            " 1.我们对您的个人信息的收集/保存/使用/对外提供/保护等规则条款，以及您的用户权利等条款;\n" +
            " 2. 约定我们的限制责任、免责条款;\n" +
            " 3.其他以颜色或加粗进行标识的重要条款。\n" +
            "如您对以上协议有任何疑问，" +
            "可通过云教育中心与我们联系。您点击“同意并继续”的行为即表示您已阅读完毕并同意以上协议的全部内容。" +
            "如您同意以上协议内容，请点击“同意”，开始使用我们的产品和服务!"
        })
        // if(!this.checked) {
        //   Toast('请阅读并同意用户协议与隐私政策')
        //   return
        // }
        try{
          Toast.loading({
            duration: 0,
            message: '注册中',
            forbidClick: true,
          });
          let resp = await tokenApi.signupBySms(this.form)
          console.log(resp)
          Toast.clear();
          Dialog({ message: '注册成功，请返回登录' });
        }catch(e){
          console.log(e)
          Toast.clear();
          Toast.fail(e.message);
        }
      }catch(e) {
        
      }
    }
  }
}
</script>

<style lang="less">
.left-text {
  .van-dialog__content {
    .van-dialog__message {
      text-align: left;
    } 
  }
}
// .van-dialog__message--has-title {
//   text-align: left !important;
// }
.main{
  display: flex;
  flex-direction: column;
}
</style>
    